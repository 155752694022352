import React, { useReducer, useState } from "react";
import "./ContactSection.css";
import FadeIn from "../../components/FadeIn/FadeIn";
import { send } from "emailjs-com";

const ContactSection = () => {
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const [validFields, setValidFields] = useState({
    validName: true,
    validEmail: true,
    validMessage: true,
  });
  const [shuttleLaunched, setShuttleLaunched] = useState(false);

  const [formValues, setFormValues] = useReducer(
    (curVals, newVals) => ({ ...curVals, ...newVals }),
    initialValues
  );
  const { name, email, message } = formValues;
  const { validName, validEmail, validMessage } = validFields;

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ [name]: value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setValidFields({
      validName: formValues.name !== "",
      validEmail: validateEmail(formValues.email),
      validMessage: formValues.message !== "",
    });

    if (
      formValues.name === "" ||
      !validateEmail(formValues.email) ||
      formValues.message === ""
    ) {
      return;
    } else {
      const toSend = {
        from_name: formValues.name,
        to_name: "Martin",
        message: formValues.message,
        reply_to: formValues.email,
      };
      send("service_8ae5dg6", "template_x4gk78h", toSend, "u1T1DJpLU1GAAz8gC")
        .then((response) => {
          setFormValues({ name: "", email: "", message: "" });
          setShuttleLaunched(true);
          setTimeout(() => {
            setShuttleLaunched(false);
          }, 2000);
        })
        .catch((err) => {
          console.log("FAILED...", err);
        });
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const resetValidFields = (inputName) => {
    if (inputName === "validName") {
      setValidFields({ validName: true, validEmail, validMessage });
    } else if (inputName === "validEmail") {
      setValidFields({ validName, validEmail: true, validMessage });
    } else {
      setValidFields({ validName, validEmail, validMessage: true });
    }
  };

  return (
    <section className="contact-wrapper container-fluid" id="contact-section">
      <FadeIn>
        <form onSubmit={onSubmitHandler} className="contact-form">
          <div className="row contact-inputs-wrapper">
            <div className="col-md-6">
              <label
                className={
                  validName
                    ? "input-label input-name"
                    : "input-label-invalid input-name"
                }
              >
                <input
                  placeholder=" "
                  type="text"
                  className={
                    name !== "" && validName
                      ? "input-style-filled"
                      : !validName
                      ? "input-style-invalid"
                      : "input-style"
                  }
                  name="name"
                  value={name}
                  onChange={handleFormChange}
                  onFocus={() => resetValidFields("validName")}
                />
                <span>Name</span>
              </label>
            </div>
            <div className="col-md-6">
              <label
                className={validEmail ? "input-label" : "input-label-invalid"}
              >
                <input
                  placeholder=" "
                  type="text"
                  className={
                    email !== "" && validEmail
                      ? "input-style-filled"
                      : !validEmail
                      ? "input-style-invalid"
                      : "input-style"
                  }
                  name="email"
                  value={email}
                  onChange={handleFormChange}
                  onFocus={() => resetValidFields("validEmail")}
                />
                <span>Email</span>
              </label>
            </div>
          </div>
          <div className="row textarea-wrapper">
            <div className="col-md-12">
              <label
                className={validMessage ? "input-label" : "input-label-invalid"}
              >
                <textarea
                  className={
                    message !== "" && validMessage
                      ? "input-style-filled"
                      : !validMessage
                      ? "input-style-invalid"
                      : "input-style"
                  }
                  placeholder=" "
                  name="message"
                  value={message}
                  onChange={handleFormChange}
                  onFocus={() => resetValidFields("validMessage")}
                />
                <span>Message</span>
              </label>
            </div>
          </div>
          <button type="submit" aria-label="Submit" className="submit-btn section-text">
            Send
          </button>
        </form>
      </FadeIn>
      <div
        className={shuttleLaunched ? "space-shuttle-launched" : "space-shuttle"}
      />
    </section>
  );
};

export default ContactSection;
