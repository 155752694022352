import React, { useState, useEffect } from "react";
import "./ScrollToTop.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpaceShuttle } from "@fortawesome/free-solid-svg-icons";

const ScrollToTop = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;

        if (currentScrollPos > 300) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
    }
  }, []);

  return (
    <div>
      <div
        className={
          "scroll-top " +
          (scrolled ? "scroll-top-vissible " : "scroll-top-hidden")
        }
      >
        {" "}
        <a href="#canvas-section" aria-label="Scroll to top">
          <div className="shuttle-wrapper">
            <FontAwesomeIcon icon={faSpaceShuttle} className="shuttle-icon" />
            <div className="exhaust-flame"></div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ScrollToTop;
