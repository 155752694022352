import React from 'react';
import FadeIn from "../../components/FadeIn/FadeIn";
import './AboutSection.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons'

const AboutSection = () => {
  return (
    <section className="about-wrapper container-fluid" id="about-section">
      <div className="about-overlay">
        <div className="row" style={{ height: '100%' }}>
          <div className="about-content col-md-12">
            <div className="row" style={{marginRight: "0px"}}>
              <div className="col-md-5 offset-md-1 about-left-section">
                <FadeIn>
                  <div className="title-wrapper">
                    <h2 className="turquoise section-title">About me</h2>
                  </div>
                  <p className="section-text">My name is Martin Popovski, I’m Senior Front-end developer based in Skopje and
                  currently working at  <span className="text-link"><a aria-label="link to current job" href="https://www.deptagency.com/">DEPT.</a></span> I have 5+ years of experience in software development. I'm design and detail oriented,
                  skilled in different technologies. Passionate about UI effects, animations and creating intuitive, dynamic user experiences.
                  Also I'm a creative problem solver and a team player. Fan of sports and outdoor activities.
                  <br></br>
                    <span className="text-link"><a href="#contact-section" aria-label="Link to contact section">Let's make something special!</a></span>
                  </p>
                </FadeIn>
              </div>
              <div className="col-md-5 social-wrapper">
                <FadeIn>
                  <ul className="social-list">
                    <li><a aria-label="link to linkedin" href="https://www.linkedin.com/in/martin-popovski-133b47134/"><FontAwesomeIcon icon={faLinkedinIn} className="social social-linked-in" /><i className="fa fa-linkedIn"></i></a></li>
                    <li><a aria-label="link to github" href="https://github.com/Martin-Popovski"><FontAwesomeIcon icon={faGithub} className="social social-github" /><i className="fa fa-github"></i></a></li>
                    <li><a aria-label="link to facebook" href="https://www.facebook.com/martin.popovski.5"><FontAwesomeIcon icon={faFacebookF} className="social social-facebook" /><i className="fa fa-facebook"></i></a></li>
                  </ul>
                </FadeIn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection;

