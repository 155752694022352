export const projects = [
  {
    name: "Field Eagle",
    info: "Inspection software for high risk industries",
    className: "field-eagle",
    link: "http://app.fieldeagle.com",
  },
  {
    name: "Field Eagle Mobile",
    info: "Mobile application for inspections",
    className: "field-eagle-mobile",
    link: "https://play.google.com/store/apps/details?id=app.fieldeagle&hl=en&gl=US",
  },
  {
    name: "Trackado",
    info: "Contract management software",
    className: "trackado",
    link: "https://app.trackado.com",
  },
  {
    name: "MenuSano",
    info: "Leading nutritional value calculator software",
    className: "menusano",
    link: "https://app.menusano.com/",
  },
  {
    name: "TikTak",
    info: "Online booking system for hospitals",
    className: "tiktak",
    link: "https://zmc.tiktak.mk/",
  },
  {
    name: "Associations",
    info: "Word associations game mobile application",
    className: "associations",
    link: "https://play.google.com/store/apps/details?id=com.martinpopovski.associations&hl=en&gl=US",
  },
  {
    name: "Plieger",
    info: "B2B E-commerce platform",
    className: "plieger",
    link: "https://plieger.nl/",
  },
  {
    name: "Plieger Mobile",
    info: "E-commerce mobile application",
    className: "plieger-mobile",
    link: "https://play.google.com/store/apps/details?id=com.plieger.plieger&hl=en&gl=US",
  },
];
