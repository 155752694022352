import React from "react";
import "./SkillsSection.scss";
import FadeIn from "../../components/FadeIn/FadeIn";

const SkillsSection = () => {
  return (
    <section className="skills-container" id="skills-section">
      <div className="skills-overlay"></div>
      <div className="skills-title">
        <FadeIn>
          <div className="title-wrapper">
            <h2 className="turquoise section-title">Skills</h2>
          </div>
          <p className="section-text">
            With several years of experience in software development, I've
            worked on multiple projects with different technologies like:
            React.js, Vue.js, Next.js, Nuxt.js, React-Native etc. I have great
            knowledge in building web pages and mobile applications that feel
            professional and are user-friendly at the same time.
            <br></br>
            <span className="text-link">
              <a href="#projects-section">
                See the projects that I've worked on.
              </a>
            </span>
          </p>
        </FadeIn>
      </div>

      <div className="skills-wrapper">
        <div className="holster">
          <div className="ball">
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech1">React.JS</span>
            </div>
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech2">Vue.JS</span>
            </div>
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech3">Nuxt.JS</span>
            </div>
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech4">Next.JS</span>
            </div>
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech5">JavaScript</span>
            </div>
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech6">TypeScript</span>
            </div>
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech7">HTML</span>
            </div>
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech8">CSS/Sass</span>
            </div>
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech9">GIT</span>
            </div>
            <div className="ring"></div>
            <div className="ring">
              <span className="tech-name tech10">React Native</span>
            </div>
            <div className="ring"></div>
            <div className="ring"></div>
            <div className="ring"></div>
            <div className="ring"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
