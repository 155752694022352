import React, { useState } from "react";
import "./Navigation.css";
const classNames = require("classnames");

const Navigation = () => {
  const [openNav, setNav] = useState(false);

  const toggleChecked = () => setNav((value) => !value);

  const closeNav = () => setNav(false);

  const navClass = classNames({
    "nav-list-mobile": true,
    "open-nav": openNav && window.innerWidth <= 768,
    "closed-nav": !openNav && window.innerWidth <= 768,
  });

  return (
    <nav className="navigation-wrapper">
      <div className="nav-icon-wrapper">
        <div
          id="nav-icon"
          onClick={toggleChecked}
          className={`${openNav ? "open" : ""}`}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <ul className={navClass}>
        <li className="nav-list-item" onClick={closeNav}>
          <a href="#about-section">About</a>
        </li>
        <li className="nav-list-item" onClick={closeNav}>
          <a href="#skills-section">Skills</a>
        </li>
        <li className="nav-list-item" onClick={closeNav}>
          <a href="#projects-section">Projects</a>
        </li>
        <li className="nav-list-item" onClick={closeNav}>
          <a href="#contact-section">Contact</a>
        </li>
      </ul>
      <ul className="nav-list">
        <li className="nav-list-item">
          <a href="#about-section">About</a>
        </li>
        <li className="nav-list-item">
          <a href="#skills-section">Skills</a>
        </li>
        <li className="nav-list-item">
          <a href="#projects-section">Projects</a>
        </li>
        <li className="nav-list-item">
          <a href="#contact-section">Contact</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
