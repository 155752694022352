import React, { useEffect } from "react";
import Particles from "react-particles-js";
import "./CanvasSection.css";
import particleOptions from "./ParticleOptions";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import nebula from "../../assets/images/nebula.jpg";

const CanvasSection = () => {
  useEffect(() => {
    const image = new Image();
    image.src = nebula;

    const element = document.querySelector(".canvas");
    image.onload = () => {
      if (element) {
        element.style.backgroundImage = `url(${nebula})`;
      }
    };
  }, []);

  return (
    <section className="canvas-wrapper container-fluid" id="canvas-section">
      <div className="row">
        <Particles className="canvas" params={particleOptions} />
        <div className="svg-container">
          <svg height="210" width="410">
            <path
              d="M 0 200 H 410 M 400 200 V0 M 400 10 H-200 M 10 0 V200"
              strokeWidth="20"
              fill="none"
              className="draw"
            />
          </svg>
          <Typist startDelay={1500} className="typist typist-web">
            <span style={{ textShadow: "1px 1px rgb(0, 0, 77)" }}>Hi!</span>
            <br />
            <span style={{ textShadow: "1px 1px rgb(0, 0, 77)" }}>
              I'm Martin Popovski,
            </span>
            <br />
            <span style={{ textShadow: "1px 1px rgb(0, 0, 77)" }}>
              Web Developer.
            </span>
          </Typist>
          <Typist startDelay={1500} className="typist typist-mobile">
            <span style={{ textShadow: "1px 1px rgb(0, 0, 77)" }}>
              Martin Popovski
            </span>
            <br />
            <span style={{ textShadow: "1px 1px rgb(0, 0, 77)" }}>
              Web Developer
            </span>
          </Typist>
        </div>
      </div>
    </section>
  );
};

export default CanvasSection;
