import React from "react";
import "./App.css";
import Navigation from "./components/Navigation/Navigation";
import CanvasSection from "./containers/CanvasSection/CanvasSection";
import AboutSection from "./containers/AboutSection/AboutSection";
import SkillsSection from "./containers/SkillsSection/SkillsSection";
import ProjectsSection from "./containers/ProjectsSection/ProjectsSection";
import ContactSection from "./containers/ContactSection/ContactSection";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

const App = () => {
  return (
    <div className="App">
      <header>
        <Navigation />
      </header>
      <main>
        <CanvasSection />
        <AboutSection />
        <SkillsSection />
        <ProjectsSection />
        <ContactSection />
        <ScrollToTop />
      </main>
    </div>
  );
};

export default App;
